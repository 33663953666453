/**
 * Created with JetBrains PhpStorm.
 * User: gschulz
 * Date: 02.08.13
 * Time: 09:04
 */
let Auth = (function () {
    let _private = {};

    _private.container = null;

    _private.form = $();

    this.init = function () {
        _private.form = $('body.auth.login').find('form');
        _private.form.parent('#login').find('.replace').remove();
        _private.form.show();
        _private.form.find('input[name=username]').focus();
        Validator.initError(_private.form);
    };

    _private.initIpadHandling = function(){
        let inputs = _private.form.find('input[type=text],input[type=password]');
        let submit = _private.form.find('input[type=submit]');
        _private.form.submit(function (event) {
            event.preventDefault();
            let isValid = true;
            inputs.each(function(i,input){
                if($.trim($(input).val()) === ''){
                    isValid = false;
                }
                return isValid;
            });
            if(!isValid){
                return false;
            }
            $.ajax({
                dataType: 'json',
                type:     'post',
                url:      location.href,
                data:     _private.form.serialize(),
                beforeSend: function(){
                    _private.form.find('div.error-message').remove();
                    inputs.prop('disabled',true);
                    submit.prop('disabled',true);
                },
                complete: function(){
                    inputs.prop('disabled',false);
                    submit.prop('disabled',false);
                },
                success:  function (response) {
                    if(!_.isUndefined(response.error_message)){
                        _private.form.prepend($('<div/>',{'class': 'error-message'}).text(response.error_message));
                    }
                    inputs.each(function(i,input){
                        let formelement = $(input).parent('div');
                        formelement.removeClass('error');
                        if(!_.isUndefined(response.errors) &&
                            !_.isUndefined(response.errors[$(input).attr('name')])){
                            formelement.addClass('error');
                        }
                    });
                    if(!_.isUndefined(response.redirect)){
                        location.href = response.redirect;
                    }
                }
            });
            return false;
        });
    };
});
